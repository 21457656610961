import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
//    option = {chart: {type: 'column' },
//    title:false,
//     xAxis: {
//         categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
//     },
//     plotOptions: {
//         series: {
//             allowPointSelect: true
//         }
//     },
//     series: [{
//         data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
//     }]
// }
constructor(private ApiService: ApiService, private route: ActivatedRoute,private router: Router) { }
user;
response;
pageloader = true;questionloader = false; 
surveydata;
survey_id = ''; 
  ngOnInit() {
    if (localStorage.getItem("currentappuser")) {
      this.user = JSON.parse(localStorage.getItem("currentappuser"));
    }  
    this.route.params.subscribe(params => {
      this.survey_id = params['id'];
    });
    
    //Highcharts.chart('linechart', this.option);
    this.ApiService.apirequest('getsurveyreport', { token: this.user.token, survey_id: this.survey_id }).subscribe(data => {
      this.response = data.json();
      this.surveydata = this.response.data;


  });
}

}
