import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { ApiService } from '../api/api.service'

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private _dataService: ApiService, private router: Router) { }
  today = new Date();
  errorlogin = false; successinfo = false;
  lg_username = '';
  lg_password = '';
  myresponse;
  successmsg;
  loginaction = true;
  errormsg; logindisabled = false;
  logourl = '';
  currentyear = new Date();
  ngOnInit() {
      this._dataService.apirequest('getlogo',{ logo: true}).subscribe(data => {
        this.myresponse = data.json();
        if (this.myresponse.status) {
          this.logourl = this.myresponse.data;
        }
      })  

    if (localStorage.getItem('errormsg')) {
      this.errormsg = localStorage.getItem('errormsg');
      this.errorlogin = true;
      localStorage.removeItem('errormsg');
    }
    if (localStorage.getItem('successmsg')) {
      this.successmsg = localStorage.getItem('successmsg');
      this.successinfo = true;
      localStorage.removeItem('successmsg');
    }

    if (localStorage.getItem('currentappuser')) {
      this.router.navigate(['/dashboard']);
    }
    $("#loginform").validate({
      errorPlacement: function (error, element) {
        return true;
      }
    });

  }
  forgotpassword() {
    this.loginaction = false;
  }
  backtologin() {
    this.loginaction = true;
  }

  doLogin() {

    if (!$("#loginform").valid()) {
    }
    else {

      if (this.loginaction) {
        this.logindisabled = true;
        this._dataService.userLogin({ username: this.lg_username, password: this.lg_password }).subscribe(data => {
          this.myresponse = data.json();
          this.logindisabled = false;
          if (this.myresponse.success) {
            if(this.myresponse.data.user_type && this.myresponse.data.user_type=='CA')
            {
            this.router.navigate(['/dashboard']);
            }
            else
            {
              this.router.navigate(['/survey']);  
            }
          }
          else {
            this.errormsg = this.myresponse.message;
            this.errorlogin = true;
          }
          setTimeout(() => {
            this.errorlogin = false;
          }, 3000);

        });
      }
      else 
      {
        this._dataService.apirequest('forgotpassword',{ username: this.lg_username}).subscribe(data => {
          this.myresponse = data.json();
          if (this.myresponse.success) {

            this.successmsg = "Check your Inbox for email with a link to reset your password. If you haven't received it,check once in your Junk/Spam folder as well.";
            this.successinfo = true;
          }
          else {
            this.errormsg = this.myresponse.message;
            this.errorlogin = true;
          }
          this.lg_username = '';
          setTimeout(() => {
            this.errorlogin = false;
            this.successinfo = false;
          }, 3000);


        })  

      }
    }

  }



}
