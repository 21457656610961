import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { ApiService } from '../api/api.service'
declare var $: any;

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
  currentyear = new Date();
  constructor(private _dataService: ApiService, private router: Router) { }

  ngOnInit() {
    this._dataService.apirequest('getlogo',{ logo: true}).subscribe(data => {
      this.myresponse = data.json();
      if (this.myresponse.status) {
        this.logourl = this.myresponse.data;
      }
    })  
  }
  logindisabled = false;
  textotp = '';
  myresponse
  errorlogin = false; successinfo = false;
  errormsg;
  logourl = '';
  verifyOtp() {
    this.logindisabled = true;
   
    this._dataService.userOTPlogin({ otp: this.textotp }).subscribe(data => {
      this.myresponse = data.json();
    
      this.logindisabled = false;
      if (this.myresponse.success) {
        this.router.navigate(['/survey/feedback', 'self', this.myresponse.survey_number]);
      }
      else {
        this.errormsg = this.myresponse.message;
        this.errorlogin = true;
      }
      setTimeout(() => {
        this.errorlogin = false;
      }, 3000);
    });
  }
}
