import { Component, OnInit, ViewChild,ElementRef} from '@angular/core';
import { ApiService } from '../api/api.service';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { NgSelectConfig } from '@ng-select/ng-select';
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare var $: any;

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {

  constructor(private ApiService: ApiService, private config: NgSelectConfig,private router:Router ,private ngxService: NgxUiLoaderService) {

  }
  surveydata = { survey_id: '', feedback_receiver_id: '', startdate: '', enddate: '', survey_status: '', invite_type: '' };
  
  pageloader = false;
  user;
  data_id = '';
  response;
  recordload = true;
  searchtext = '';
  search_status = '';
  rolelist: any = [];
  userlist: any = [];
  dropdownSettings = {};
  datalist = [
    { name: 'Jill', email: 'jill@email.com', age: 15,disabled:false  },
    { name: 'Henry', email: 'henry@email.com', age: 10,disabled:false  },
    { name: 'Meg', email: 'meg@email.com', age: 7,disabled:true  }
  ];
    @ViewChild('downloadreport') downloadreport: ElementRef;
  surveyreviewerdata = {feedback_receiver_id:'', feedback_giver: {},feedback_giver_id:[] };

  todaydate = new Date();
  dateoptions = {};
  dateoptions1 = {}; surveylist = [];
  takensurveyscheduledlist = [];
  roleinfo;
  chartdata = {};
  ngOnInit() {
    if (localStorage.getItem("currentappuser")) {
      this.user = JSON.parse(localStorage.getItem("currentappuser"));
      this.roleinfo = this.user.roleinfo;
    }
    this.listAllsurvey();
    // this.datalist = [];
    this.todaydate.setHours(0, 0, 0, 0);
    this.dateoptions = { format: 'DD MMM YYYY', useCurrent: false, minDate: this.todaydate };
    this.dateoptions1 = { format: 'DD MMM YYYY', useCurrent: false, minDate: this.todaydate };
    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'user_name',
      enableCheckAll:false,
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

  }
onAdd($event) {
    this.datalist[0].disabled = true;
   
}

onRemove($event) {
 
  this.datalist[0].disabled = true;
}
  reset() {
    this.data_id = '';
    this.surveydata = { survey_id: '', feedback_receiver_id: '', startdate: '', enddate: '', survey_status: '', invite_type: '' };
  }
  surveyschedule(surveyinfo) {
    this.surveydata.survey_id = surveyinfo._id;
  }
  getstatus_of_fbg(dataobject,type)
  {
   if(type=='inactive')
   {
    var n = 0;
    dataobject.forEach(element => {
     if(!element.feedback_status) 
     {
      n += 1; 
     } 
    });
    return n;
   } 
   if(type=='accepted') 
   {
    var n = 0;
    dataobject.forEach(element => {
     if(element.feedback_status) 
     {
      n += 1; 
     } 
    });
    return n;

   } 
  }
  dateselect(type, action) {

    setTimeout(() => {
      if (action == 'init' || (this.surveydata.startdate != '' && this.surveydata.enddate != '')) {
        if (type == "1" && this.surveydata.startdate != '') {
          this.dateoptions1 = {
            format: 'DD MMM YYYY', useCurrent: false, minDate: new Date(this.surveydata.startdate),
          };
          this.surveydata.enddate = '';
        }
      }
    }, 100);

  }

  surveygivenscheduledlist = [];
  listAllsurvey() {
    this.recordload = true;
    var data = { token: this.user.token, 'type': 'alluser' };
    this.ApiService.apirequest('getAllScheduleSurvey', { token: this.user.token, type: 'currentuser' }).subscribe(data => {
      this.takensurveyscheduledlist = data.json().data;
      this.recordload = false;
    });
    this.ApiService.apirequest('getotherScheduleSurvey', { token: this.user.token, type: 'currentuser' }).subscribe(data => {
      this.surveygivenscheduledlist = data.json().data;
    });
    this.ApiService.apirequest('listalluser', data).subscribe(data => {
      this.userlist = data.json().data;
       this.loadusers()
    });
    this.ApiService.apirequest('listallrole', { token: this.user.token }).subscribe(data => {
      this.rolelist = [];
       data.json().data.forEach(value=>{
        if( value.role_type == "survey"){
        this.rolelist.push(value)
          
        }
       })
    });
    this.ApiService.apirequest('getAllSurvey', { token: this.user.token }).subscribe(data => {
      this.surveylist = data.json().data;
    })
  }
  onsurveySubmit() {
    var data = { formdata: this.surveydata, data_id: this.data_id, token: this.user.token }
    this.pageloader = true;
    this.ApiService.apirequest('scheduleSurvey', data).subscribe(data => {
      this.pageloader = false;
      this.response = data.json();
      if (this.response.status) {
        swal("Success", this.response.message, "success");
      }
      else {
        swal("Oops!", this.response.message, "error");
      }
      this.listAllsurvey();
      $('#surveyModal').modal('hide');
    });
  }

  surveystatusupdate(surveygivendata,type)
  {
    this.pageloader = true;
    this.data_id = surveygivendata._id;
    var data = { type:type, data_id: this.data_id, token: this.user.token }
    this.ApiService.apirequest('surveystatusupdate', data).subscribe(data => {
      this.pageloader = false;
      this.response = data.json();
      if (this.response.status) {
        surveygivendata.feedback_giver_id.feedback_accept_status = type;
      }
      else
      {
        swal("Oops!", this.response.message, "error"); 
      }

    });

  }

 async  rejectRequest(surveygivendata,type){
  const {value: text} = await swal({
  title: 'Enter the reason for rejection ',  
  input: 'textarea',
  inputPlaceholder: 'Type your reason here...',
  showCancelButton: true
})

if (text) { 
this.pageloader = true;
    this.data_id = surveygivendata._id;
    var data = { type:type, data_id: this.data_id, token: this.user.token , reason: text }
    this.ApiService.apirequest('surveystatusupdate', data).subscribe(data => {
      this.pageloader = false;
      this.response = data.json();
      if (this.response.status) {
        surveygivendata.feedback_giver_id.feedback_accept_status = type;
        this.listAllsurvey()
      }
      else
      {
        swal("Oops!", this.response.message, "error"); 
      }
    });

}

// console.log(text)
 
  }

  getcollectionID;

  onsurveyreviewerSubmit() {

  var userId = JSON.parse(localStorage.getItem("currentappuser"));
    if (!$("#surveyreviewerForm").valid()) {
    }
    else {
      
      var data = { formdata: this.surveyreviewerdata,delmemb : this.deleteMember, data_id:this.getcollectionID.scheduledinfo[0]._id, token: this.user.token }

      
      this.pageloader = true;
      this.ApiService.apirequest('setreviewersurvey', data).subscribe(data => {
        this.pageloader = false;
        this.response = data.json();
        if (this.response.status) {
          swal("Success", this.response.message, "success");
          this.listAllsurvey();
           this.surveyreviewerdata = {feedback_receiver_id:'', feedback_giver: {},feedback_giver_id:[] };
           this.selecteduserdata = [];
          this.deleteMember =[];
          $('#reviewerModal').modal('hide');
        }
        else {
          swal("Oops!", this.response.message, "error");
        }
      });
    }
  }



  setreviewer(selectedreviewer) {

  this.getcollectionID = selectedreviewer;
    this.surveyreviewerdata = {feedback_receiver_id:'', feedback_giver: {},feedback_giver_id:[] };
    this.selecteduserdata = [];
    this.data_id = selectedreviewer._id;
    this.surveyreviewerdata.feedback_receiver_id = selectedreviewer.scheduledinfo[0].feedback_receiver_id
    this.selecteduserdata.push(selectedreviewer.scheduledinfo[0].feedback_receiver_id);
    if(selectedreviewer.scheduledinfo[0].feedback_giver_id)
    {
      this.surveyreviewerdata.feedback_giver = selectedreviewer.scheduledinfo[0].feedback_giver;
      this.surveyreviewerdata.feedback_giver_id = selectedreviewer.scheduledinfo[0].feedback_giver_id;  
    selectedreviewer.scheduledinfo[0].feedback_giver_id.forEach(element => {
      this.selecteduserdata.push(element.user_id);  
    });
    
    }
   
  }
  delete_record(record) {
    var data = { data_id: record._id, token: this.user.token }
    swal({
      title: 'Are you sure?',
      text: 'The record will be removed permanently.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel it'
    }).then((result) => {
      if (result.value) {
        this.pageloader = true;
        this.ApiService.apirequest('deleterole', data).subscribe(data => {
          this.response = data.json();
          this.pageloader = false;
          if (this.response.status) {
            swal('Confirmed', this.response.message, 'success');
          }
          else {
            swal('Error', this.response.message, 'error');
          }
          this.listAllsurvey();
        });
      }
    })
  }

// format date

 formatDate(date) {
  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

var datenew =new Date(date);
  var day = datenew.getDate();
  var monthIndex = datenew.getMonth();
  var year = datenew.getFullYear();

  return day + ' ' + monthNames[monthIndex] + ' ' + year;
}





checkScheduleDate(surveynumber,endDate,startdate,type){
  
  var currenDate = moment();
  var SurveyEndDate = moment(endDate).endOf('days');
  var SurveystartDate = moment(startdate).startOf('days')
 
  if(currenDate <= SurveyEndDate && currenDate >= SurveystartDate)
  {

   this.router.navigate([ '/survey/feedback',type,surveynumber]);
  }
  else if(currenDate < SurveystartDate){

    swal('Sorry!', "You can start your survey on " + this.formatDate(startdate));
  }
  else if(currenDate > SurveyEndDate){
    swal('Sorry!', "Your survey has been expired. Please contact your survey manager for more details.");
  }
}


onItemSelect(event,mm,feedrowsID){

}

// to remove

deleteMember =[];
  onRemove2(item) {
   // console.log(item.value._id);
   this.deleteMember.push(item.value._id)
    this.selecteduserdata = this.selecteduserdata.filter(v => v !== item.value._id);

 this.surveyreviewerdata.feedback_giver_id = this.surveyreviewerdata.feedback_giver_id.filter(v => v.user_id !== item.value._id)
    
  }


// to add
 selecteduserdata = [];
  userlistrow = [];
  loadusers() {
    this.userlistrow = this.userlist.filter(item => this.selecteduserdata.indexOf(item._id) == -1);
  }
  updatedselectedlist(item,role_id) {
    this.selecteduserdata.push(item._id);
    this.surveyreviewerdata.feedback_giver_id.push({user_id:item._id,role_id:role_id});
  
  }


notifyFBR(data,surveyinfo){

console.log(surveyinfo);
var obj = {_id: data._id,enddate : surveyinfo['scheduledinfo'][0].enddate, token: this.user.token,survey_id:surveyinfo._id,survey_name:surveyinfo.survey_name }
      this.pageloader = true;  
      this.ApiService.apirequest('notificationcall', obj).subscribe(data => {
        this.pageloader = false;
        this.response = data.json();
        // console.log(this.response)
        if (this.response.status) {
          swal("Success", this.response.message, "success");
          $('#reviewerModalResend').modal('hide');
        }
        else {
          swal("Oops!", this.response.message, "error");
        }
      });

}


// download pdf
detailed_report;

reportData;colorsdata;
getsurveyreport(data,survey_name){
  // console.log(data);
  this.reportData = data;
  this.ApiService.apirequest('getsurveyreports', { token: this.user.token, survey_number: data.survey_number, survey_name: survey_name,feedback_receiver_id: data.feedback_receiver_id,type:'surveyinfo' ,detailed_report : this.detailed_report }).subscribe(data => {
      this.detailed_report = data.json().data;
      if(data.json().success){
        this.colorsdata = data.json().surveyinfo.color;
        // console.log(data.json().success ,this.detailed_report);
this.downloadreports(this.detailed_report,this.reportData,survey_name)
      }
    });
}


downloaderurl = '';

 downloadreports(detailed,data,survey_name)
  {
    
    this.ngxService.start();
    var colors = ['#438dd2','#ae0e5a','#75bb37'];
    var competencyrow = [];
    var others_arr = {};
    var self_arr = [];
    var seriesrow = [];
    detailed.map(function (element, index) {
      competencyrow.push(element.competency_name);
      var role = Object.keys(element.avg);
      role.map(function (element1, index1) {
        if (!others_arr[element1] && element1 != 'self') { others_arr[element1] = []; }
        if (element1 == 'self') { self_arr.push(parseFloat(element.avg[element1])); }
        else { others_arr[element1].push(parseFloat(element.avg[element1])) }
      });
    });
    seriesrow.push({
      name: 'Self Score',
      data: self_arr,
      color: this.colorsdata.selfcolor,
      type: 'spline',
    });
    colors[0] = this.colorsdata.management;
    colors[1] = this.colorsdata.other;
    var rolearray = Object.keys(others_arr);
    rolearray.map(function (element1, index1) {
      const textcaptial = element1.charAt(0).toUpperCase() + element1.slice(1);
      seriesrow.push({
        name: textcaptial + ' Score',
        data: others_arr[element1],
        color: colors[index1],
        type: 'column',
      });
    });
    this.chartdata = {
      chart: {
        zoomType: 'xy', width: 700,height: 400,
      },
      title: false,
      subtitle: false,
      xAxis: {
        categories: competencyrow,
        crosshair: true
      },
      yAxis: {
        min: 0.00,
        max: 5.00,
        title: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.2f}'
          }
        }
      },
      credits: {
        enabled: false
      },
      tooltip: { enabled: false },
      series: seriesrow,
    } 

    this.ApiService.apirequest('getsurveyreports', { token: this.user.token, survey_number: data.survey_number, survey_name: survey_name, type: 'downloadreport',chartdata:this.chartdata ,detailed_report : detailed }).subscribe(result => {
      if(result.json().status)
      {
    // console.log('falsekk');
      this.downloaderurl = result.json().data;
      setTimeout(() => {
      // let el: HTMLElement = this.downloadreport.nativeElement as HTMLElement;
      // el.click();
      this.downloadURI(this.downloaderurl )
      this.ngxService.stop(); 
     
    }, 100);
      }
      else
      {
       swal('Oops...', 'Error! Try Again', 'error');
      } 

    });

  }


 downloadURI(uri) 
{
  // console.log(uri);
    var link = document.createElement("a");
    link.download = uri;
    link.href = '/reports/'+uri;
    document.body.appendChild(link)
    link.click();
    link.remove() 
}

getUrl(user_profileImg) {
  if (user_profileImg) {
    return "url('profileimage/" + user_profileImg + "')";
  }
  else {
    return "url('assets/images/default_user_icon.png')";
  }
}




}

