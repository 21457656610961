import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from '../api/api.service';
import swal from 'sweetalert2';
declare var $: any;
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-survey-schedule',
  templateUrl: './survey-schedule.component.html',
  styleUrls: ['./survey-schedule.component.css']
})
export class SurveyScheduleComponent implements OnInit {
  surveydata = { survey_id: '', survey_name: '', feedback_receiver_id: [], startdate: '', enddate: '', survey_status: '', invite_type: '' };
  survey_id = '';
  surveyreviewerdata = { feedback_giver: {}, feedback_giver_id: [], feedback_receiver_id: '' }
  pageloader = false;
  user;
  data_id = '';
  response;
  recordload = true;
  rolelist: any = [];
  userlist: any = [];
  todaydate = new Date();
  dateoptions = {};
  dateoptionsFilter = {};
  dateoptions1 = {}; surveylist = [];
  surveyscheduledlist = []; search_survey = ''; date_from = ""; date_to = "";
  scheduled_users = [];
  available_users = [];
  disableddata = false;
  chartdata = {};
  finarArray = [];
  p: number = 1;
  itemsPerPage: number = 50;
  @ViewChild('downloadreport') downloadreport: ElementRef;
  constructor(private ApiService: ApiService, private ngxService: NgxUiLoaderService) { }
  ngOnInit() {
    if (localStorage.getItem("currentappuser")) {
      this.user = JSON.parse(localStorage.getItem("currentappuser"));
    }
    this.listAllsurvey();
    this.todaydate.setHours(0, 0, 0, 0);
    this.dateoptions = { format: 'DD MMM YYYY', useCurrent: false, minDate: this.todaydate };
    this.dateoptionsFilter = { format: 'DD MMM YYYY', useCurrent: false };
    this.dateoptions1 = { format: 'DD MMM YYYY', useCurrent: false, minDate: this.todaydate };
    $("#surveyForm").validate({
      errorPlacement: function (error, element) {
        return true;
      }
    });

    $('#OpenImgUpload').click(function () { $('#imgupload').trigger('click'); });
  }

  reset() {
    this.disableddata = false;
    this.data_id = '';
    this.surveydata = { survey_name: '', survey_id: '', feedback_receiver_id: [], startdate: '', enddate: '', survey_status: '', invite_type: '' };
  }
  select_survey(type) {
    var scheduledata = this.surveyscheduledlist.filter(item => item.survey_id == this.surveydata.survey_id);
    this.scheduled_users = scheduledata.map(function (element, index) { return element.userdata._id; });
    if (type == 'add') {

      this.available_users = this.userlist.filter(item => this.scheduled_users.indexOf(item._id) == -1);
    }
    else {
      this.available_users = this.userlist.filter(item => item._id == this.surveydata.feedback_receiver_id);
    }
  }
  createnewsurveyclick() {
    this.reset();
  }
  edit_record(record) {
    this.data_id = record._id;
    this.surveydata.feedback_receiver_id = [record.feedback_receiver_id];
    this.select_survey('edit');
    var ss = new Date(record.startdate)
    ss.setHours(0, 0, 0, 0);
    var ee = new Date(record.enddate)
    ee.setHours(23, 59, 59, 0);
    this.surveydata.survey_id = record.survey_id;
    this.surveydata.survey_status = record.survey_status;
    this.surveydata.invite_type = record.invite_type;
    this.surveydata.survey_name = record.survey_name;
    this.dateoptions = { format: 'DD MMM YYYY', useCurrent: false, minDate: ss };
    this.dateoptions1 = { format: 'DD MMM YYYY', useCurrent: false };
    this.surveydata.startdate = moment(record.startdate).format('DD MMM YYYY');
    setTimeout(() => {
      this.surveydata.enddate = moment(record.enddate).format('DD MMM YYYY');
    }, 300);
    this.disableddata = true;

  }
  dateselect(type, action) {
    setTimeout(() => {
      if (action == 'init' || (this.surveydata.startdate != '')) {
        if (type == "1" && this.surveydata.startdate != '') {
          this.dateoptions1 = {
            format: 'DD MMM YYYY', useCurrent: false, minDate: new Date(this.surveydata.startdate),
          };
          this.surveydata.enddate = '';
        }
      }
    }, 100);

  }
  selecteduserdata = [];
  userlistrow = [];
  loadusers() {
    this.userlistrow = this.userlist.filter(item => this.selecteduserdata.indexOf(item._id) == -1);
  }
  updatedselectedlist(item, role_id) {
    this.selecteduserdata.push(item._id);
    this.surveyreviewerdata.feedback_giver_id.push({ user_id: item._id, role_id: role_id });
  }
  getcompletedlist(data) {
    if (data && data.length > 0) {
      var completeddata = data.filter(element => element.feedback_status === 'Completed');
      return completeddata.length;
    }
    else {
      return 0;
    }
  }
  setreviewer(selectedreviewer) {
    this.surveyreviewerdata = { feedback_receiver_id: '', feedback_giver: {}, feedback_giver_id: [] };
    this.selecteduserdata = [];
    this.data_id = selectedreviewer._id;
    this.surveyreviewerdata.feedback_receiver_id = selectedreviewer.feedback_receiver_id
    this.selecteduserdata.push(selectedreviewer.feedback_receiver_id);
    if (selectedreviewer.feedback_giver_id) {
      this.surveyreviewerdata.feedback_giver = selectedreviewer.feedback_giver;
      this.surveyreviewerdata.feedback_giver_id = selectedreviewer.feedback_giver_id;
      selectedreviewer.feedback_giver_id.forEach(element => {
        this.selecteduserdata.push(element.user_id);
      });
    }
  }
  deleteMember = [];
  onRemove(item) {
    this.deleteMember.push(item.value._id)
    this.selecteduserdata = this.selecteduserdata.filter(v => v !== item.value._id);
    this.surveyreviewerdata.feedback_giver_id = this.surveyreviewerdata.feedback_giver_id.filter(v => v.user_id !== item.value._id)
  }
  listAllsurvey() {
    this.recordload = true;
    var data = { token: this.user.token, 'type': 'alluser' };
    this.surveyscheduledlist = [];
    this.ApiService.apirequest('getAllScheduleSurvey', data).subscribe(data => {
      this.response = data.json().data;
      this.recordload = false;
      if (this.response && this.response[0]) {
        this.response.forEach(element => {
          element.scheduledinfo.forEach(scheduled => {
            this.surveyscheduledlist.push(scheduled);
            var lastindex = this.surveyscheduledlist.length - 1;
            this.surveyscheduledlist[lastindex].survey_name = element.survey_name;
          });
        });
      }
      else {
        this.surveyscheduledlist = [];
      }
    });
    this.ApiService.apirequest('listalluser', data).subscribe(data => {
      this.userlist = data.json().data;
      this.loadusers();
    });
    this.ApiService.apirequest('listallrole', { token: this.user.token }).subscribe(data => {
      this.rolelist = [];
      // this.rolelist = data.json().data;
      data.json().data.filter((value) => {
        if (value.role_type == "survey") {
          this.rolelist.push(value)
        };
      })

    });
    this.ApiService.apirequest('getAllSurvey', { token: this.user.token }).subscribe(data => {
      this.surveylist = data.json().data;
    })
  }
  onsurveySubmit() {
    if (!$("#surveyForm").valid()) {
    }
    else {
      var data = { formdata: this.surveydata, data_id: this.data_id, token: this.user.token }
      this.pageloader = true;
      if (this.data_id == '') {
        var requrl = 'scheduleSurvey';
      }
      else {
        var requrl = 'updatescheduleSurvey';
      }
      this.ApiService.apirequest(requrl, data).subscribe(data => {
        this.pageloader = false;
        this.response = data.json();
        if (this.response.status) {
          swal("Success", this.response.message, "success");
          this.listAllsurvey();
          $('#surveyModal').modal('hide');

        }
        else {
          swal("Oops!", this.response.message, "error");
        }
      });
    }
  }
  onsurveyreviewerSubmit() {
    if (!$("#surveyreviewerForm").valid()) {
    }
    else {
      var data = { formdata: this.surveyreviewerdata, delmemb: this.deleteMember, data_id: this.data_id, token: this.user.token }
      this.pageloader = true;
      this.ApiService.apirequest('setreviewersurvey', data).subscribe(data => {
        this.pageloader = false;
        this.response = data.json();
        if (this.response.status) {
          swal("Success", this.response.message, "success");
          this.listAllsurvey();
          this.surveyreviewerdata = { feedback_receiver_id: '', feedback_giver: {}, feedback_giver_id: [] };
          this.selecteduserdata = [];
          this.deleteMember = [];
          this.data_id = '';
          $('#reviewerModal').modal('hide');
        }
        else {
          swal("Oops!", this.response.message, "error");
        }
      });
    }
  }
  delete_record(record) {
    var data = { data_id: record._id, token: this.user.token }
    swal({
      title: 'Are you sure?',
      text: 'The record will be removed permanently.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel it'
    }).then((result) => {
      if (result.value) {
        this.pageloader = true;
        this.ApiService.apirequest('deleteschedulesurvey', data).subscribe(data => {
          this.response = data.json();
          this.pageloader = false;
          if (this.response.status) {
            swal('Confirmed', this.response.message, 'success');
            this.listAllsurvey();
          }
          else {
            swal('Error', this.response.message, 'error');
          }

        });
      }
    })
  }
  showStatusOfFBR;
  infoOfFBR;
  fbgStatus(data) {
    console.log(data);
    // this.userlistrow = data;
    this.survey_id = data.survey_id;
    this.survey_name = data.survey_name;
    $("#reviewerModalResend").modal('show');
    this.showStatusOfFBR = data.feedbackgiverinfo;
    this.infoOfFBR = data.feedback_giver_id;
    this.finarArray = [];
    if (data.feedback_giver_id) {
      this.showStatusOfFBR.forEach(val => {
        data.feedback_giver_id.forEach(docs => {
          if (val._id == docs.user_id) {
            this.finarArray.push({
              _id: val._id, user_department: val.user_department,
              user_email: val.user_email, user_id: val.user_id, user_status: val.user_status
              , user_type: val.user_type, enddate: data.enddate, "feedback_status": docs.feedback_accept_status,survey_c_status:docs.feedback_status,"reason": docs.reason
            })
          }
        })

      })
      //console.log( this.finarArray);
    }
  }
  notifyFBR(data) {
    var obj = { _id: data._id, enddate: data.enddate, token: this.user.token,survey_id:this.survey_id,survey_name:this.survey_name }
    this.pageloader = true;
    this.ApiService.apirequest('notificationcall', obj).subscribe(data => {
      this.pageloader = false;
      this.response = data.json();
      // console.log(this.response)
      if (this.response.status) {
        swal("Success", this.response.message, "success");
        $('#reviewerModalResend').modal('hide');
      }
      else {
        swal("Oops!", this.response.message, "error");
      }
    });

  }
  returnValue() {
    return 3;
  }
  // excel file to json
  data;
  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      // this.data = (XLSX.utils.sheet_to_json(ws, {header: 1}));
      var workbook = XLSX.read(bstr, {
        type: "binary", cellDates: true
      });
      /* DO SOMETHING WITH workbook HERE */
      var first_sheet_name = workbook.SheetNames[0];
      /* Get worksheet */
      var worksheet = workbook.Sheets[first_sheet_name];
      this.data = XLSX.utils.sheet_to_json(worksheet, {
        raw: true
      });

      // start insert in db
      this.pageloader = true;
      if (this.data.length < 0) {
        swal("Oops!", 'Excel file is not having any records.', "error");
        this.pageloader = false;
      }
      else {
        var count = 0;
        if (this.data[0] && this.data[0].startdate && this.data[0].enddate && this.data[0].survey_name) {
          this.data.map(ele => {

            var dd = ele.startdate.split('.');
            var jj = ele.enddate.split('.');
            ele.startdate = moment(dd[2] + '-' + dd[1] + '-' + dd[0]).format('YYYY-MM-DD');
            ele.enddate = moment(jj[2] + '-' + jj[1] + '-' + jj[0]).format('YYYY-MM-DD');

            var dataObj = { formdata: ele, data_id: this.data_id, token: this.user.token }
            var requrl = 'uploadScheduleSurvey';
            this.ApiService.apirequest(requrl, dataObj).subscribe(data => {
              this.pageloader = false;
              this.response = data.json();
              count++;
              if (this.response.status) {
                if (this.data.length == count) {
                  this.listAllsurvey();
                  $('#bulkupload').trigger("reset");
                  swal("Success", this.response.message, "success");
                }
              }
              else {
                swal("Oops!", this.response.message, "error");
              }
            });
          })
        }
        else {
          swal("Oops!", 'Please upload valid file templete for Sheduling survey.', "error");
          this.pageloader = false;
        }
      }
      // end
    };
    reader.readAsBinaryString(target.files[0]);
  }
  survey_start = new Date(); survey_end = new Date(); report_status = 'pending';
  usernameDisplay;
  department_name;
  user_profileImage;
  search = "";
  rolearr = [];
  feedbackgiver;
  feedBackdata;
  rolearrvalue;
  survey_number;
  survey_name;
  downloaderurl = '';
  detailed_report; colorsdata;
  getsurveyreports(data) {
    this.usernameDisplay = data.userdata.user_name;
    this.department_name = data.userdata.user_department;
    this.user_profileImage = data.userdata.user_profileImg;
    this.survey_number = data.survey_number;
    this.survey_name = data.survey_name;
    this.survey_start = new Date(data.startdate);
    this.survey_end = new Date(data.enddate);
    this.report_status = data.report_status;
    this.rolearr = []; this.rolearrvalue = [];
    //console.log(this.survey_name);
    this.ApiService.apirequest('getsurveyreports', { token: this.user.token, survey_number: this.survey_number, survey_name: this.survey_name, feedback_receiver_id: data.feedback_receiver_id, type: 'surveyinfo' }).subscribe(data => {
      this.detailed_report = data.json().data;
      this.colorsdata = data.json().surveyinfo.color;
      if (this.detailed_report && this.detailed_report[0] && this.detailed_report[0].avg_all) {
        this.rolearr = Object.keys(this.detailed_report[0].avg_all);
        this.rolearrvalue = Object.values(this.detailed_report[0].avg_all);
      }
      
      $('#myModal_survey').modal('show');
    });
  }
  downloadreports() {
    
    this.ngxService.start();
    var colors = ['#438dd2', '#ae0e5a', '#75bb37'];
    var competencyrow = [];
    var others_arr = {};
    var self_arr = [];
    var seriesrow = [];
    this.detailed_report.map(function (element, index) {
      competencyrow.push(element.competency_name);
      var role = Object.keys(element.avg);
      role.map(function (element1, index1) {
        if (!others_arr[element1] && element1 != 'self') { others_arr[element1] = []; }
        if (element1 == 'self') { self_arr.push(parseFloat(element.avg[element1])); }
        else { others_arr[element1].push(parseFloat(element.avg[element1])) }
      });
    });
    seriesrow.push({
      name: 'Self Score',
      data: self_arr,
      color: this.colorsdata.selfcolor,
      type: 'spline',
    });
    colors[0] = this.colorsdata.management;
    colors[1] = this.colorsdata.other;
    var rolearray = Object.keys(others_arr);
    rolearray.map(function (element1, index1) {
      const textcaptial = element1.charAt(0).toUpperCase() + element1.slice(1);
      seriesrow.push({
        name: textcaptial + ' Score',
        data: others_arr[element1],
        color: colors[index1],
        type: 'column',
      });
    });
    this.chartdata = {
      chart: {
        zoomType: 'xy', width: 700, height: 400,
      },
      title: false,
      subtitle: false,
      xAxis: {
        categories: competencyrow,
        crosshair: true
      },
      yAxis: {
        min: 0.00,
        max: 5.00,
        title: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.2f}'
          }
        }
      },
      credits: {
        enabled: false
      },
      tooltip: { enabled: false },
      series: seriesrow,
    }
    this.ApiService.apirequest('getsurveyreports', { token: this.user.token, survey_number: this.survey_number, type: 'downloadreport', chartdata: this.chartdata, detailed_report: this.detailed_report, survey_name: this.survey_name }).subscribe(data => {
      if (data.json().status) {
        this.downloaderurl = data.json().data;
        setTimeout(() => {
          let el: HTMLElement = this.downloadreport.nativeElement as HTMLElement;
          el.click();
          this.ngxService.stop();

        }, 100);
      }
      else {
        swal('Oops...', 'Error! Try Again', 'error');
      }

    });
  }
  getUrl(user_profileImg) {
    if (user_profileImg) {
      return "url('profileimage/" + user_profileImg + "')";
    }
    else {
      return "url('assets/images/default_user_icon.png')";
    }
  }
}
