import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import {NgxPaginationModule} from 'ngx-pagination';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ApiService } from './api/api.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { HeaderComponent } from './header/header.component';
import { SearchByPipe } from './pipe/search-by.pipe';
import { FooterComponent } from './footer/footer.component';
import { UsersComponent } from './users/users.component';
import { SurveyComponent } from './survey/survey.component';
import { SurveyScheduleComponent } from './survey-schedule/survey-schedule.component';
import { RolesComponent } from './roles/roles.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {A2Edatetimepicker} from 'ng2-eonasdan-datetimepicker';
import { SearchbyfieldPipe } from './pipe/searchbyfield.pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { VerificationComponent } from './users/verification/verification.component';
import { NoprivilegeComponent } from './noprivilege/noprivilege.component';
import { FeedbackComponent } from './survey/feedback/feedback.component';
import { DatefilterPipe } from './pipe/datefilter.pipe';
import { NotificationsComponent } from './notifications/notifications.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NotfoundComponent } from './notfound/notfound.component';
import { OtpComponent } from './otp/otp.component';
import { ReportComponent } from './survey/report/report.component';
import { NgxUiLoaderModule } from  'ngx-ui-loader';
import { FaqComponent } from './faq/faq.component';
import { OrderByPipe } from './pipe/order-by.pipe';
import { SortListPipe } from './pipe/sort-list.pipe';
import {PopoverModule} from "ngx-popover";

const Routes = [
   { path: 'login', component: LoginComponent },
   { path: '', component: LoginComponent },
   { path: 'dashboard', component: DashboardComponent ,canActivate: [AuthGuard]},
   { path: 'users', component: UsersComponent ,canActivate: [AuthGuard]},
   { path: 'users/verify/:id', component: VerificationComponent},
   { path: 'survey', component: SurveyComponent ,canActivate: [AuthGuard]},
   { path: 'survey-schedule', component: SurveyScheduleComponent ,canActivate: [AuthGuard]},
   { path: 'roles', component: RolesComponent ,canActivate: [AuthGuard]},
   { path: 'survey/feedback/:type/:id', component: FeedbackComponent ,canActivate: [AuthGuard]},
   { path: 'survey/report/:id', component: ReportComponent ,canActivate: [AuthGuard]},
   { path: 'accessdenied', component: NoprivilegeComponent ,canActivate: [AuthGuard]},
   { path: 'notifications', component: NotificationsComponent ,canActivate: [AuthGuard]},
   { path: 'otp', component: OtpComponent  },
   { path: 'help', component: FaqComponent  },
   { path: '**',  component: NotfoundComponent },
   
  ]

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    SearchByPipe,
    FooterComponent,
    UsersComponent,
    SurveyComponent,
    RolesComponent,
    SearchbyfieldPipe,
    SurveyScheduleComponent,
    VerificationComponent,
    NoprivilegeComponent,
    FeedbackComponent,
    DatefilterPipe,
    NotificationsComponent,
    NotfoundComponent,
    OtpComponent,
    ReportComponent,
    FaqComponent,
    OrderByPipe,
    SortListPipe
  ],
  imports: [
   BrowserModule,A2Edatetimepicker,
   FormsModule,NgSelectModule,
   ReactiveFormsModule,
   RouterModule.forRoot(Routes),NgMultiSelectDropDownModule.forRoot(), HttpModule,
   HttpClientModule,
   NgxPaginationModule,NgxUiLoaderModule,PopoverModule,
   ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
 ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
