import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router) { }
    menuList = ['users', 'roles'];
    allprivilege; public pagename: any;
    user;roleinfo
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.user = JSON.parse(localStorage.getItem("currentappuser"));
        if (this.user) {
            this.allprivilege = this.user.user.user_type;
            this.roleinfo = this.user.roleinfo;
            this.pagename = state.url.slice(1);
           
            if (this.allprivilege != "CA") {
                if (this.pagename == "users" || this.pagename == "dashboard"  || this.pagename == "roles" || this.pagename == "survey-schedule" ) {
                   if(this.roleinfo.survey_status!="Y")
                   {
                    this.router.navigate(['/accessdenied']);
                   }
                }

            }
        }
        else
        {
            this.router.navigate(['']);    
        }



        if (localStorage.getItem('expirecheck')) {
            var record = JSON.parse(localStorage.getItem('expirecheck'));

            if (new Date().getTime() > record.timestamp) {
                localStorage.removeItem('currentappuser');
                localStorage.removeItem('expirecheck');
                localStorage.removeItem("profile_pic");
                this.router.navigate(['']);
                return false;
            } else {

                return true;
            }
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['']);
        return false;

    }
}
