import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api/api.service';
declare var $ :any;

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  constructor(private ApiService: ApiService) { }
  pageloader = false;
  user;
  response;searchword;
  recordload = true;notificationlist = [];
  ngOnInit() {
    if (localStorage.getItem("currentappuser")) {
      this.user = JSON.parse(localStorage.getItem("currentappuser"));
    }
    this.recordload = true;
  

if(this.user.user.user_type =='CA'){
      this.ApiService.apirequest('listallactivities', { token: this.user.token}).subscribe(data => {
    this.notificationlist = data.json().data;
     this.recordload = false;
$('.alertview').hide();
  })

}
else{
    this.ApiService.apirequest('notifications', { token: this.user.token,type:'list' }).subscribe(data => {
      this.notificationlist = data.json().data;
      this.recordload = false;
      $('.alertview').hide();
      
    });
  }
}

}
