import { Component } from '@angular/core';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { ApiService } from './api/api.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  user;  today = new Date();
  pageloader =false;
 
  constructor(private router:Router,private _dataService: ApiService) {}
  get isLoggedIn() {
    if (localStorage.getItem("currentappuser")) {
      this.user = JSON.parse(localStorage.getItem("currentappuser"));
      return true;
    }
    else {
      return false;
    }
  }

  getRouteclass(){

    if (this.router.url === '/survey'){
     return "container-fluid";
    }
    else
    {
      return '';
    }
  }



}
