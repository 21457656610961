import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import swal from 'sweetalert2';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  constructor(private ApiService: ApiService, private route: ActivatedRoute, private router: Router) { }
  user;
  response;
  pageloader = true; questionloader = false;
  finishsurvey = false;
  surveyfinished = false;
  aboutFlag = false;
  errormessage = false;
  surveydata; surveyinfo = { questions_about_survey: [], survey_name: '' }; surveyscheduleinfo = [];
  survey_id = ''; survey_type = ''; ratingscaledata = [];
  ratingscaledatalist = [];
  competency_data = [];
  questioninfo = []
  active_comp_index = 0;
  active_question_index = 0; ratingscaledatavalue = []; ratingscaledesc = [];
  answerdata = {};
  answerdatavalue = [];
  current_competency;
  survey_status = 'Progress';
  progressdata = [];
  surveyuserinfo = {};
  ngOnInit() {
    if (localStorage.getItem("currentappuser")) {
      this.user = JSON.parse(localStorage.getItem("currentappuser"));
    }


    this.route.params.subscribe(params => {
      this.survey_id = params['id'];
      this.survey_type = params['type'];
    });
    this.listAllsurvey();


    $('body').scrollspy({ target: ".navbar", offset: 50 });
  }
  getratingscale(ratingid) {
    this.ApiService.apirequest('getratingscale', { token: this.user.token, survey_id: this.survey_id, ratingid: ratingid.survey_rating }).subscribe(data => {
      this.ratingscaledata = data.json().data;
      this.ratingscaledatalist = Object.keys(this.ratingscaledata['pointsdata']['point_name']).map(key => this.ratingscaledata['pointsdata']['point_name'][key]);
      this.ratingscaledatavalue = Object.keys(this.ratingscaledata['pointsdata']['point_value']).map(key => this.ratingscaledata['pointsdata']['point_value'][key]);
      this.ratingscaledesc = Object.keys(this.ratingscaledata['pointsdata']['point_desc']).map(key => this.ratingscaledata['pointsdata']['point_desc'][key]);

    })
  }
  listAllsurvey() {
    this.ApiService.apirequest('getsurveyinfo', { token: this.user.token, survey_id: this.survey_id, survey_type: this.survey_type }).subscribe(data => {
      this.response = data.json();
      this.surveydata = this.response.data;
      if (this.response.success) {

        this.surveyinfo = this.surveydata.survey_info;
        this.getratingscale(this.surveyinfo);
        this.surveyscheduleinfo = this.surveydata;
        this.competency_data = this.surveydata.competencyinfo;
        this.surveyuserinfo = this.surveydata.userinfo;
        if (this.survey_type == 'self') {
          if (this.surveydata.feedback_receiver_survey_status && this.surveydata.feedback_receiver_survey_status == 'Progress') {

            this.answerdatavalue = this.surveydata.feedback_receiver_surveydata;
            this.answerdatavalue.forEach((element, index) => {
              element.ques_data.forEach((element2, index2) => {
                this.answerdata[element.comp_id + '-' + index2] = String(element2.ratingvalue);
              });
            });

            this.active_comp_index = this.answerdatavalue.length - 1;
            this.active_question_index = this.answerdatavalue[this.answerdatavalue.length - 1]['ques_data'].length - 1;
            if (this.competency_data[this.active_comp_index]) {
              this.current_competency = this.competency_data[this.active_comp_index]['comp_id'];
            }
          }

          if (this.surveydata.feedback_receiver_survey_status && this.surveydata.feedback_receiver_survey_status == 'Completed') {
            this.surveyfinished = true;
            this.active_comp_index = this.competency_data.length;
          }
        } 
        else if (this.survey_type == 'invite') {

          if (this.surveydata.feedback_giver_id && this.surveydata.feedback_giver_id.feedback_status == 'Progress') {
            this.answerdatavalue = this.surveydata.feedback_giver_id.feedback_data;


            this.answerdatavalue.forEach((element, index) => {
              element.ques_data.forEach((element2, index2) => {
                this.answerdata[element.comp_id + '-' + index2] = String(element2.ratingvalue);
              });
            });

            this.active_comp_index = this.answerdatavalue.length - 1;
            this.active_question_index = this.answerdatavalue[this.answerdatavalue.length - 1]['ques_data'].length - 1;
            if (this.competency_data[this.active_comp_index]) {
              this.current_competency = this.competency_data[this.active_comp_index]._id;
            }

          }

          if (this.surveydata.feedback_giver_id && this.surveydata.feedback_giver_id.feedback_status == 'Completed') {
            this.surveyfinished = true;
            this.active_comp_index = this.competency_data.length;
          }
        }

        this.pageloader = false;
      }
      else {
        swal('Sorry! We could not load your survey. Please Try Again', this.response.message, 'error').then((result) => {
          if (result.value) {
            this.router.navigate(['/survey']);
          }
        })
        this.pageloader = false;
      }
    })
  }
  useranswered(comp_id, comp_index, ques_index, question_id, ratingvalue) {

    if (!this.answerdatavalue[comp_index]) {
      this.answerdatavalue[comp_index] = {};
      this.answerdatavalue[comp_index]['comp_id'] = comp_id;
      this.answerdatavalue[comp_index]['ques_data'] = [];
      this.answerdatavalue[comp_index]['ques_data'][ques_index] = { "question_id": question_id, "ratingvalue": ratingvalue };
    }
    else {
      this.answerdatavalue[comp_index]['comp_id'] = comp_id;
      this.answerdatavalue[comp_index]['ques_data'][ques_index] = { "question_id": question_id, "ratingvalue": ratingvalue };
    }
    this.current_competency = comp_id;
    this.errormessage = false;
    setTimeout(() => {
      if (this.finishsurvey) { this.nextquestion('finish'); } else { this.nextquestion('next'); }
    }, 300);
  }
  finalQuestionAboutSurvey = [];
  aboutSurvey(questions_about_survey) {
    console.log('q abt survey', questions_about_survey, this.questioninfo);
    let array = Array.from(this.questioninfo, item => item || '');
  
    this.questioninfo= array;
    console.log('array',  this.questioninfo );
    let checkdata = questions_about_survey.filter((val,index)=> val.required && !this.questioninfo[index]);
    console.log('data 11111111111', checkdata);
    this.finalQuestionAboutSurvey = [];
    this.questioninfo.forEach((val, index) => {
      this.finalQuestionAboutSurvey.push({ question: questions_about_survey[index], answer: val, updated_answers:val})
    })
   
    console.log('finalQuestionAboutSurvey', this.finalQuestionAboutSurvey);

    // answeraboutsurvey
    if(checkdata&& checkdata.length<=0) { 
    this.ApiService.apirequest('answeraboutsurvey', { token: this.user.token, survey_id: this.survey_id, ques_ans_about_survey: this.finalQuestionAboutSurvey }).subscribe(data => {
      this.aboutFlag = false;
    });
  } else {
    swal('Please enter all required fields', '', 'warning')
  }
  }
  nextquestion(actiontype) {

    if (this.answerdatavalue && this.answerdatavalue[this.active_comp_index] && this.answerdatavalue[this.active_comp_index]['ques_data'][this.active_question_index] && this.answerdatavalue[this.active_comp_index]['ques_data'][this.active_question_index] != '') {

      this.questionloader = true;
      if (this.competency_data[this.active_comp_index] && this.competency_data[this.active_comp_index].competency_question
        && this.competency_data[this.active_comp_index].competency_question.length == (this.active_question_index + 1)) {
        this.active_comp_index += 1;
        this.active_question_index = 0;
        if (this.competency_data[this.active_comp_index]) {
          this.current_competency = this.competency_data[this.active_comp_index]._id;
        }
      }
      else {
        this.active_question_index += 1;
      }
      if ((this.active_comp_index == (this.competency_data.length - 1)) && (this.competency_data[this.active_comp_index].competency_question.length == (this.active_question_index + 1))) {

        this.finishsurvey = true;
      }

      if (actiontype == 'finish') {
        
        if (this.surveyinfo.questions_about_survey && this.surveyinfo.questions_about_survey.length > 0) {
          this.aboutFlag = true;
        }
        this.surveyfinished = true;
        this.survey_status = 'Completed';
      }
      this.errormessage = false;

      this.ApiService.apirequest('surveyanswered', { survey_status: this.survey_status, token: this.user.token, answerdata: this.answerdatavalue, survey_id: this.survey_id, survey_type: this.survey_type }).subscribe(data => {
        setTimeout(() => {
          this.questionloader = false;
        }, 300);
      });
    }
    else {
      this.errormessage = true;
    }
  }
  previousquestion() {

    this.questionloader = true;
    this.current_competency = this.competency_data[this.active_comp_index]._id
    if (this.active_question_index == 0) {
      this.active_comp_index -= 1;
      this.active_question_index = (this.competency_data[this.active_comp_index].competency_question.length - 1);
    }
    else {
      this.active_question_index -= 1;
    }
    setTimeout(() => {
      this.questionloader = false;
    }, 300);
    this.finishsurvey = false;
    this.errormessage = false;
  }
getUrl(user_profileImg) {
  if (user_profileImg) {
    return "url('profileimage/" + user_profileImg + "')";
  }
  else {
    return "url('assets/images/default_user_icon.png')";
  }
}
}
