import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api/api.service';
import * as moment from 'moment';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private ApiService: ApiService) { }
  pageloader =false;user;
  rolelist =[];userlist = [];scheduledlist = [];
  activitylist =[];
  ngOnInit() {

    if (localStorage.getItem("currentappuser")) {
      this.user = JSON.parse(localStorage.getItem("currentappuser"));
    }
    var data = {token:this.user.token }
    this.ApiService.apirequest('listallrole',data).subscribe(data => { 
      this.rolelist = data.json().data;
   });
   this.ApiService.apirequest('listalluser', data).subscribe(data => {
    this.userlist = data.json().data;
   
  });
  this.ApiService.apirequest('listallscheduledlist', data).subscribe(data => {
    this.scheduledlist = data.json().data;
  })
  this.ApiService.apirequest('listallactivities', data).subscribe(data => {
    this.activitylist = data.json().data;
  })



  }
  gettimeago(date)
  {
    return moment(date).fromNow();
  }


}
