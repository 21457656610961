import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import { ApiService } from '../api/api.service';
import swal from 'sweetalert2';

declare var $ :any;


@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {

  constructor(private ApiService: ApiService) { }
  pageloader =false;user;
  rolelist:any = [];
  data_id = ''; response; recordload = true;searchtext='';search_status='';
  roledata = {role_name: '', role_status: '', survey_status: 'N' , role_type : ''} ;
  addFlag = "";
  dateoptions = {
    format: 'DD MMM YYYY', useCurrent: false, maxDate: new Date(),
  };
  ngOnInit() {
    this.roledata.role_type = 'user';
    if (localStorage.getItem("currentappuser")) {
      this.user = JSON.parse(localStorage.getItem("currentappuser"));
    }
    this.listAllrole();
  
  }
  listAllrole()
  {
    this.recordload = true;
    var data = {token:this.user.token }
    this.ApiService.apirequest('listallrole',data).subscribe(data => { 
       this.rolelist = data.json().data;
       this.recordload = false;
    })
  }
  editrole(data)
  {

    this.addFlag = "Edit Role";
    this.roledata = Object.assign({}, data);
    this.data_id = data._id;
  }
  reset()
  {
    this.addFlag = "Add New Role";
    this.data_id = '';
    this.roledata = {role_name: '', role_status: '', survey_status: 'N',role_type: 'user'} 
   
  }
  onroleSubmit(roleForm: NgForm)
  {
  if(roleForm.value.role_type != 'survey'){
    roleForm.value.survey_role_type =""
  }
  var data = {roledata:roleForm.value,data_id:this.data_id,token:this.user.token }
  this.pageloader = true;
  this.ApiService.apirequest('addRoles',data).subscribe(data => { 
    this.pageloader = false;
    this.response = data.json();
    if(this.response.status)
    {
      swal("Success",this.response.message, "success");
    }
    else
    {
      swal("Oops!", this.response.message, "error");
    }
    this.listAllrole();
    $('#roleModal').modal('hide');

     
  });


  }


  delete_record(record)
  {
    var data = { data_id:record._id, token: this.user.token }
    swal({
      title: 'Are you sure?',
      text: 'The record will be removed permanently.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel it'
    }).then((result) => {
      if (result.value) {
        this.pageloader = true;
        this.ApiService.apirequest('deleterole',data).subscribe(data => {
          this.response = data.json();
          this.pageloader = false;
          if(this.response.status)
          { 
          swal('Confirmed',this.response.message,'success');
          }
        else
        {
          swal('Error',this.response.message,'error');
        }
        this.listAllrole();
        });
      } 
    })
  }

}

