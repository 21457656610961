import { Component, OnInit, ViewContainerRef, AfterViewInit, AfterContentInit } from '@angular/core';
import { ApiService } from '../api/api.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
declare var $: any;

import swal from 'sweetalert2';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private ApiService: ApiService, private router: Router, _vcr: ViewContainerRef) { }
  user; curent_userdata; notificationcount = 0; profileurl = '';pageloader = false;response;
  dateoptions = {
    format: 'DD MMM YYYY', useCurrent: false, maxDate: new Date(),
  };
  profilenew = ""; 
  passworddata = { currentpassword: '', newpassword: '', confirm_password: '' };
  roleBaseProfileUpdate;
  ngOnInit() {
    if (localStorage.getItem("currentappuser")) {
      this.user = JSON.parse(localStorage.getItem("currentappuser"));
   
        this.roleBaseProfileUpdate = this.user.user.user_type;
      if (!this.curent_userdata) {
        this.curent_userdata = JSON.parse(localStorage.getItem("currentappuser")).user;
        this.profileurl = (localStorage.getItem("profile_pic") != '') ? 'profileimage/' + localStorage.getItem("profile_pic") : 'assets/images/default.png';
      }
      
    }
    $("#userprofileForm").validate({
      errorPlacement: function (error, element) {
        return true;
      }
    });
    $("#passwordform").validate({
      errorPlacement: function (error, element) {
        return true;
      }
    });
    this.notification();
    //setInterval(function(){   this.notification();  }, 4000);
  }
  notification()
  {
    this.ApiService.apirequest('notifications', { token: this.user.token, type: 'unseen' }).subscribe(data => {
      this.notificationcount = data.json().data.length;
    });
   
  }
  userlogout() {
    this.ApiService.userlogout();
    this.router.navigate(['/']);
  }
  passwordupdate() {
    if (!$("#passwordform").valid()) {

    }
    else {
      this.ApiService.apirequest('passwordupdate',{_id: this.user.user._id,passworddata:this.passworddata, token:this.user.token}).subscribe(data => {
        this.response = data.json();
        if (this.response.success) {
          swal({position: 'top-end',type: 'success',title: "Success",text: this.response.message,showConfirmButton: false, timer: 3000})
          $('#change_pwd').modal('hide');
        }
        else {
          swal({position: 'top-end',type: 'error',title: "Oops!",text: this.response.message,showConfirmButton: false, timer: 3000})
        }
      });
    }
  }
  
  onprofileSubmit(userprofileForm: NgForm) {
    if (!$("#userprofileForm").valid()) {
    }
    else {
     
      var data = { userdata: userprofileForm.value,profileimage:this.profilenew, data_id: this.user.user._id, token: this.user.token,userprofileImg:this.user.user.user_profileImg,type:'update' }
     data.userdata.date_of_birth = moment(data.userdata.date_of_birth).format('DD-MM-YYYY');
      data.userdata.date_of_joining = moment(data.userdata.date_of_joining).format('DD-MM-YYYY');
      this.pageloader = true;
      this.ApiService.apirequest('addUser', data).subscribe(data => {
        this.pageloader = false;
        this.response = data.json();
        if (this.response.status) {

          if (this.profilenew != '') {
            $('#profile_photo').attr('src', this.profilenew);
          }
          this.user.user = this.response.responsedata;
          localStorage.setItem('currentappuser', JSON.stringify(this.user));
          localStorage.setItem('profile_pic', this.response.result);

          $('#editprofile').modal('hide');
          swal({position: 'top-end',type: 'success',title: "Success",text: this.response.message,showConfirmButton: false, timer: 3000})
        }
        else {
          swal({position: 'top-end',type: 'error',title: "Oops!",text: this.response.message,showConfirmButton: false, timer: 3000})
        }
      });
    }


  }
  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      if(event.target.files[0].type=='image/jpeg' || event.target.files[0].type=='image/png' || event.target.files[0].type=='image/gif')
      {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        $('#editprofilepic').attr('src', event.target.result);
        $('#editprofilepic').show();  
        this.profilenew = event.target.result;

      }
      reader.readAsDataURL(event.target.files[0]);
    }
    else
    {
      $('#editprofilepic').attr('src', "");  
      $('#editprofilepic').hide();  
      this.profilenew = "";
    }
  }
  }

}
