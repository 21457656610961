import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../api/api.service';
import swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

declare var $: any;


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  constructor(private ApiService: ApiService) { }
  pageloader = false; user;
  userlist: any = [];
  data_id = ''; response; searchtext = ''; search_status = '';
  dateoptions = {
    format: 'DD MMM YYYY', useCurrent: false, maxDate: new Date(),
  };
  eighteenYearsAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
  dateofbirthOptions = {
    format: 'DD MMM YYYY', maxDate: this.eighteenYearsAgo
  }
  userdata = {
    user_name: '', user_email: '', user_gender: '', user_department: '', user_location: '',
    date_of_joining: '', date_of_birth: '', user_role_id: '', user_status: '', user_id: '', user_profileImg: ''
  }
  recordload = true;
  rolelist = [];
  p: number = 1; 
  itemsPerPage: number = 50; 
  ngOnInit() {
    if (localStorage.getItem("currentappuser")) {
      this.user = JSON.parse(localStorage.getItem("currentappuser"));
    }
    this.ApiService.apirequest('listallrole', { token: this.user.token }).subscribe(data => {
      data.json().data.filter((value) => {
        if (value.role_type == "user") {
          this.rolelist.push(value)
        };
      })
    })
    this.listAllUser();
    $("#userForm").validate({
      errorPlacement: function (error, element) {
        return true;
      }
    });
    $('#OpenImgUpload').click(function () { $('#imgupload').trigger('click'); });
  }

  listAllUser() {
    this.recordload = true;
    var data = { token: this.user.token }
    this.ApiService.apirequest('listalluser', data).subscribe(data => {
      this.userlist = data.json().data;
      this.recordload = false;
    })
  }
  edituser(data) {
    this.reset();
    this.userdata = Object.assign({}, data);
   // this.userdata = data;
    this.data_id = data._id;
    this.userdata.date_of_birth = data.date_of_birth;
  }
  reset() {
    this.data_id = '';
    this.userdata = {
      user_name: '', user_email: '', user_gender: '', user_department: '', user_location: '',
      date_of_joining: '', date_of_birth: '', user_id: '', user_role_id: '', user_status: '', user_profileImg: ''
    }
  }
  delete_record(record) {
    var data = { data_id: record._id, token: this.user.token }
    swal({
      title: 'Are you sure?',
      text: 'The record will be removed permanently.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel it'
    }).then((result) => {
      if (result.value) {
        this.pageloader = true;
        this.ApiService.apirequest('deleteuser', data).subscribe(data => {
          this.response = data.json();
          this.pageloader = false;
          if (this.response.status) {
            swal('Confirmed', this.response.message, 'success');
          }
          else {
            swal('Error', this.response.message, 'error');
          }
          this.listAllUser();
        });
      }
    })
  }

  onuserSubmit(userForm: NgForm) {
    if (!$("#userForm").valid()) {
    }
    else {

      var data = { userdata: userForm.value, data_id: this.data_id, token: this.user.token }
      this.pageloader = true;
      data.userdata.date_of_birth = moment(data.userdata.date_of_birth).format('DD-MM-YYYY');
      data.userdata.date_of_joining = moment(data.userdata.date_of_joining).format('DD-MM-YYYY');
      this.ApiService.apirequest('addUser', data).subscribe(data => {
        this.pageloader = false;
        this.response = data.json();
        if (this.response.status) {
          $('#userModal').modal('hide');
          this.listAllUser();
          swal({ position: 'top-end', type: 'success', title: "Success", text: this.response.message, showConfirmButton: false, timer: 3000 })
        }
        else {
          swal({ position: 'top-end', type: 'error', title: "Oops!", text: this.response.message, showConfirmButton: false, timer: 3000 })
        }
      });
    }
  }

  // excel file to json
  data;
  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      var workbook = XLSX.read(bstr, {
        type: "binary", cellDates: true
      });
      /* DO SOMETHING WITH workbook HERE */
      var first_sheet_name = workbook.SheetNames[0];
      /* Get worksheet */
      var worksheet = workbook.Sheets[first_sheet_name];
      this.data = XLSX.utils.sheet_to_json(worksheet, {
        raw: true
      });
      // start insert in db
      this.pageloader = true;

      if (this.data.length < 0) {
        swal("Oops!", 'Excel file is not having any records.', "error");
        this.pageloader = false;
      }
      else {
        var count = 0;
        if(this.data[0] && this.data[0].date_of_birth && this.data[0].date_of_joining && this.data[0].user_email && this.data[0].user_id)
        {
          console.log('data1111111111', this.data)
        this.data.map(ele => {
          console.log('data11111122', ele)
          var dd = ele.date_of_birth.split('.');
          var jj = ele.date_of_joining.split('.');
          ele.date_of_birth = moment(dd[2] + '-' + dd[1] + '-' + dd[0]).format('YYYY-MM-DD');
          ele.date_of_joining = moment(jj[2] + '-' + jj[1] + '-' + jj[0]).format('YYYY-MM-DD');
          var dataObj = { userdata: ele, data_id: this.data_id, token: this.user.token }
          var requrl = 'uploadUser';
          this.ApiService.apirequest(requrl, dataObj).subscribe(data => {
            this.pageloader = false;
            this.response = data.json();
            count++;
            if (this.response.status) {
              if (this.data.length == count) {
                swal("Success", this.response.message, "success");
                this.listAllUser();
                $('#bulkupload').trigger("reset");
              }
            }
            else {
              swal("Error", this.response.message, "error");
            }

          });
        })
      }
      else
      {
        swal("Oops!", 'Please upload valid file templete for Sheduling survey.', "error");
        this.pageloader = false;   
      }
      }
      // end
    };
    reader.readAsBinaryString(target.files[0]);
  }
}


