import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../api/api.service'
declare var $: any;
@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit {

  constructor(private _dataService: ApiService, private router: Router, private route: ActivatedRoute) { }
  today = new Date();
  errorpassword = false;
  lg_password;
  lg_password_c;
  myresponse;
  errormsg; logindisabled = false;
  passwordtoken;
  logourl = '';
  currentyear = new Date();
  ngOnInit() {
    if (localStorage.getItem('currentappuser')) {
      localStorage.removeItem('currentappuser');
      localStorage.removeItem('expirecheck');
      localStorage.removeItem("profile_pic");
    }
    this.passwordtoken = this.route.snapshot.paramMap.get('id');
    $("#passwordform").validate({
      errorPlacement: function (error, element) {
        return true;
      }
    });
    this._dataService.apirequest('checkpasswordtoken', {'passwordtoken': this.passwordtoken }).subscribe(data => {
      this.myresponse = data.json();
      if(this.myresponse.status==false) {
          localStorage.setItem('errormsg','Invalid Link.Please try Again. ');
          this.router.navigate(['/']);
        }
    });
    this._dataService.apirequest('getlogo',{ logo: true}).subscribe(data => {
      this.myresponse = data.json();
      if (this.myresponse.status) {
        this.logourl = this.myresponse.data;
      }
    })  

  }

  setpassword() {
    if (!$("#passwordform").valid()) {
    }
    else {
      this.logindisabled = true;
      this._dataService.apirequest('setnewpassword', { password: this.lg_password, cpassword: this.lg_password_c, 'passwordtoken': this.passwordtoken }).subscribe(data => {
        this.myresponse = data.json();
        this.logindisabled = false;
        if (this.myresponse.success) {
          localStorage.setItem('successmsg','Password updated successfully');
          this.router.navigate(['/']);
        }
        else {
          this.errormsg = this.myresponse.message;
          this.errorpassword = true;
        }
        setTimeout(() => {
          this.errorpassword = false;
        }, 3000);

      });
    }
  }

}
