import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';  

@Pipe({
  name: 'datefilter'
})
export class DatefilterPipe implements PipeTransform {

  start: any
  transform(itemList: any, startDate: any, endDate: any): any {  
    var filteredArray = new Array();  
    var datePipe = new DatePipe("en-US");  
    if(startDate && endDate)
    {
        startDate = new Date(startDate);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(endDate);
        endDate.setHours(23,59,59,999);

    if (itemList && itemList.length) {  
        itemList.forEach(proj => {
            if(proj.startdate)
                {
            var orderStartDate  = new Date(proj.startdate);
                }
            if (orderStartDate >= startDate && orderStartDate <= endDate) {  
                filteredArray.push(proj);  
            }  
        });  
    }
    return filteredArray;  
}
else
{
    return  itemList
}
}  

}
