import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable, Subject, ReplaySubject, from, of, range } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  result;
  user;roleinfo;survey_number;invite_type;
  public token: string;

  constructor(private _http: Http) { }

  userLogin(user) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.post('/login', JSON.stringify(user), { headers: headers }).pipe(map(res => {
      let status = res.json();

      if (status.success == true) {
        this.token = status.token;
        this.user = status.data;
        this.roleinfo = status.roleinfo;
       
        var expirationMS = 350 * 60 * 1000;

        var record = { value: JSON.stringify('expiry'), timestamp: new Date().getTime() + expirationMS }
        localStorage.setItem('expirecheck', JSON.stringify(record));
        if(status.data.user_profileImg)
        {
          localStorage.setItem('profile_pic', status.data.user_profileImg);
        }
        else
        {
          localStorage.setItem('profile_pic',''); 
        }
      
        localStorage.setItem("currentappuser", JSON.stringify(
          {
            user: this.user,
            token: this.token,
            roleinfo : this.roleinfo
          }));
        return res;
      }
      else {
        return res;
      }
    }));

  }

  apirequest(url, params) {
    return this._http.post(url, params).pipe(map(res => res));
  }
  userlogout() {
    this.token = null;
    localStorage.removeItem("currentappuser");
    localStorage.removeItem("profile_pic");
    localStorage.removeItem('expire_check');
  }



  userOTPlogin(user) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this._http.post('/OTPlogin', JSON.stringify(user), { headers: headers }).pipe(map(res => {
      let status = res.json();
     
      if (status.success == true) {
        this.token = status.token;
        this.user = status.data;
        this.roleinfo = status.roleinfo;
        this.survey_number = status.survey_number;
        this.invite_type = status.invite_type;
        var expirationMS = 350 * 60 * 1000;

        var record = { value: JSON.stringify('expiry'), timestamp: new Date().getTime() + expirationMS }
        localStorage.setItem('expirecheck', JSON.stringify(record));
        if(status.data.user_profileImg)
        {
          localStorage.setItem('profile_pic', status.data.user_profileImg);
        }
        else
        {
          localStorage.setItem('profile_pic',''); 
        }
      
        localStorage.setItem("currentappuser", JSON.stringify(
          {
            user: this.user,
            token: this.token,
            roleinfo : this.roleinfo,
            survey_number:this.survey_number,
            invite_type: this.invite_type
          }));
        return res;
      }
      else {
        return res;
      }
    }));

  }
}

